var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_logistics"},[_c('back-step',{attrs:{"goList":[{
      url: '/cases',
      name: _vm.$t('casesDetail.casesChildren.common.bl')
    }, {
      url: ("/cases/detail?caseId=" + (_vm.$route.query.caseId) + "&curePlanId=" + (_vm.$route.query.curePlanId)),
      name: _vm.$t('casesDetail.casesChildren.common.blxq')
    }],"currentTxt":_vm.$t('casesDetail.casesChildren.allLogistics.qbwl')}}),_c('ul',{staticClass:"logistics_list"},_vm._l((_vm.logistics.list),function(item){return _c('li',{key:item.id,staticClass:"single_li"},[_c('div',{staticClass:"single_l"},[_c('div',{staticClass:"s_l_l"},[_c('div',{staticClass:"s_l_l_pic",staticStyle:{"min-width":"0.56rem"},style:({backgroundImage: ("url('http://file.aismile.cn/express/" + (item.logisticsInfo.companyCode) + ".png')")})}),_c('div',{staticClass:"s_l_l_box"},[_c('h5',{staticClass:"s_l_l_h5"},[_vm._v(_vm._s(item.logisticsInfo.logisticsCompany))]),_c('p',{staticClass:"s_l_l_p01"},[_vm._v(_vm._s(_vm.$t('casesDetail.casesChildren.allLogistics.ydh'))+"："+_vm._s(item.logisticsInfo.logisticsNo))]),_c('p',{staticClass:"s_l_l_p02"},[_vm._v(_vm._s(_vm.$t('casesDetail.casesChildren.allLogistics.wjnr'))+"："+_vm._s(item.logisticsName || '-'))])])]),_c('div',{staticClass:"s_l_r"},[_c('p',{staticClass:"s_l_l_r_p"},[_vm._v(_vm._s(item.logisticsInfo.senderProvinceName || '-'))]),_c('div',{staticClass:"s_l_l_r_box"},[_c('p',{staticClass:"s__l_r_p"},[_vm._v(_vm._s(_vm.statusObj[item.status]))]),_c('p',{staticClass:"s__l_r_line"}),_c('p',{staticClass:"s__l_r_p01"},[_vm._v(_vm._s(item.logisticsInfo.createDatetime))])]),_c('p',{staticClass:"s_l_l_r_p"},[_vm._v(_vm._s(item.logisticsInfo.receiverProvinceName || '-'))])])]),_c('div',{staticClass:"single_r main_big_btn main_theme_color_btn",on:{"click":function($event){return _vm.toLogisticsDetail(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('casesDetail.casesChildren.allLogistics.ckxq'))+" ")])])}),0),(!_vm.isLoading)?_c('el-pagination',{staticClass:"fr",attrs:{"page-sizes":[10, 20, 30, 50, 100],"current-page":+_vm.queryParams.pageNum,"page-size":+_vm.queryParams.pageSize,"background":"","layout":"total, sizes, prev, pager, next","total":_vm.logistics.total},on:{"size-change":function($event){return _vm.changeRouteQuery({
      pageSize:arguments[0],
      pageNum: 1
    })},"current-change":function($event){return _vm.changeRouteQuery({
      pageNum:arguments[0]
    })}}}):_vm._e(),_c('FullLoading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]})],1)}
var staticRenderFns = []

export { render, staticRenderFns }